.video-preview-container {
  position: relative;
  width: 120px;
  height: 80px;
}

.preview-thumbnail {
  transition: opacity 0.3s ease;
}

.preview-thumbnail.hidden {
  opacity: 0;
}

.preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.preview-video.loaded {
  opacity: 1;
}

.video-preview-modal {
  top: 50%;
  padding-bottom: 0;
}

.video-preview-modal .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.video-preview-modal .ant-modal-body {
  padding: 0;
}

.video-modal-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: black;
  border-radius: 8px;
  overflow: hidden;
}

.modal-video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Override antd modal styles for better video viewing */
.video-preview-modal .ant-modal-wrap {
  overflow: hidden;
}

/* Add a subtle transition effect */
.ant-modal {
  transition: opacity 0.3s ease;
}

.modal-close-icon {
  color: white;
  font-size: 24px;
  position: absolute;
  top: -30px;
  right: -30px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.modal-close-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

/* Ensure the close button is always visible */
.video-preview-modal .ant-modal-close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1000;
} 