.feed-container {
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
}

.filter-section {
  position: fixed;
  top: 64px;
  left: 256px;
  right: 0;
  z-index: 98;
  background: white;
  padding: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
}

/* Main filter area */
.filter-row {
  max-width: 1400px;
  margin: 0 auto !important;
  padding: 16px 24px;
  display: flex;
  gap: 24px;
  align-items: center;
  background: linear-gradient(to right, #ffffff, #fafafa);
}

/* Filter group styling */
.filter-group {
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.filter-label {
  font-size: 12px;
  font-weight: 500;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: 2px;
}

/* Select styling */
.filter-row .ant-select {
  width: 100%;
}

.filter-row .ant-select-selector {
  height: 38px !important;
  padding: 0 12px !important;
  border: 1.5px solid #e6e6e6 !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  background: white !important;
  transition: all 0.2s ease !important;
}

.filter-row .ant-select:hover .ant-select-selector {
  border-color: #1890ff !important;
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.1) !important;
}

.filter-row .ant-select-focused .ant-select-selector {
  border-color: #1890ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1) !important;
}

.filter-row .ant-select-selection-placeholder {
  color: #8c8c8c;
  font-size: 14px;
}

.filter-row .ant-select-selection-item {
  font-weight: 500;
}

.ant-select-item-option-content {
  font-size: 14px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 600;
  background-color: rgba(24, 144, 255, 0.08);
}

/* Status dot styling */
.ant-tag {
  line-height: 14px;
  padding: 0 4px;
}

/* Batch actions styling */
.batch-actions-row {
  max-width: 1400px;
  margin: 0 auto !important;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8faff;
  border-top: 1px solid #f0f0f0;
}

.selected-count {
  font-size: 13px;
  font-weight: 600;
  color: #1890ff;
  background: rgba(24, 144, 255, 0.08);
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.batch-actions-right {
  display: flex;
  gap: 8px;
}

.batch-actions-right .ant-btn {
  height: 36px;
  padding: 0 20px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.batch-actions-right .ant-btn-primary {
  background: #389e0d;
}

.batch-actions-right .ant-btn-primary:hover {
  background: #52c41a;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(56, 158, 13, 0.2);
}

.batch-actions-right .ant-btn-danger {
  background: #cf1322;
}

.batch-actions-right .ant-btn-danger:hover {
  background: #f5222d;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(207, 19, 34, 0.2);
}

/* Adjust margin-top to account for navbar + filter section height */
.feed-container .ant-card {
  margin-top: 80px;
}

/* Add some spacing between filters on mobile */
@media screen and (max-width: 768px) {
  .filter-row > div {
    margin-bottom: 16px;
  }
  
  .filter-row > div:last-child {
    margin-bottom: 0;
  }
}

.ant-table-row {
  cursor: pointer;
}

.ant-table-cell {
  vertical-align: top;
}

.ant-image {
  width: 100%;
}

.ant-select-disabled {
  opacity: 1 !important;
}

.ant-select-disabled .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.85) !important;
}

/* Improve checkbox area interaction */
.ant-table-selection-column {
  padding: 16px !important;
  width: 48px;
  transition: background-color 0.3s;
}

.ant-table-selection-column .ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
}

/* Optional: Add hover effect to make checkbox area more visible */
.ant-table-cell.ant-table-selection-column:hover {
  background-color: rgba(0, 0, 0, 0.02);
} 